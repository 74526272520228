import React, { ComponentProps, ReactNode } from 'react';
import logo from '@/assets/phenom-logo.svg';

import styles from './SplashScreen.module.css';
import { twMerge } from 'tailwind-merge';
import glow from '@/assets/login-glow.png';

interface SplashScreenProps extends ComponentProps<'div'> {
  logoHeader?: boolean;
  showBackgroundImage?: boolean;
  showGlowEffect?: boolean;
  children: ReactNode;
}

const SplashScreen = ({
  logoHeader = true,
  children,
  showBackgroundImage = false,
  showGlowEffect = false,
}: SplashScreenProps) => {
  return (
    <div
      className={twMerge(
        styles.splashScreen,
        'flex h-dvh min-w-fit flex-col items-center justify-center',
        showBackgroundImage && styles.backgroundImg,
      )}
    >
      {logoHeader && (
        <div className="mt-5 min-w-52 max-w-72 md:mt-8">
          <img
            id="phnm-logo"
            src={logo}
            alt="Phenom Poker Logo"
            className={twMerge('relative mx-auto max-w-[60%]', styles.z55)}
          />
        </div>
      )}
      {showGlowEffect && (
        <div className="-mt-[4em] flex h-screen w-screen items-center justify-center">
          <img src={glow} alt="glow effect" className="h-[34em] w-[45em]" />
        </div>
      )}
      {children}
    </div>
  );
};

export default SplashScreen;
