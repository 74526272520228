import { useEffect } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import ROUTES from '@/routers/Routes';
import LogoLoadingSpinner from '@/components/LogoLoadingSpinner/LogoLoadingSpinner';

import SplashScreen from './SplashScreen/SplashScreen';
import { useMe } from '../hooks/useMe';

const AuthGuard = () => {
  const { ready, authenticated, user } = usePrivy();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('referral') || searchParams.get('r');
  // React Query hook is always called, but only fetches when enabled
  const { isLoading } = useMe({
    enabled: ready && authenticated,
    userId: user?.id,
  });
  useEffect(() => {
    if (referralCode) {
      sessionStorage.setItem('referralCode', referralCode);
    }
  }, [referralCode]);

  if (!ready || (authenticated && isLoading)) {
    return (
      <SplashScreen logoHeader={false}>
        <LogoLoadingSpinner size="20" />
      </SplashScreen>
    );
  }

  if (!authenticated) {
    return <Navigate to={ROUTES.LOGIN} state={{ redirectTo: location }} />;
  }

  return authenticated ? <Outlet /> : <Navigate to={ROUTES.LOGIN} />;
};

export default AuthGuard;
