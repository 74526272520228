import axiosBaseApi from '../common/lib/axiosBaseApi';
import {
  GetTablePayload,
  GetUserTablesResponse,
  JoinTablePayload,
  JoinTableResponse,
  ReserveTableResponse,
  ReserveTableSeatPayload,
  TableIdPayload,
  autoTopUpPayload,
} from './tableServicesTypes';
import { TableState } from './tableState';
import { getAxiosInstance } from '../common/lib/axiosInstanceCache';

export const joinTableRest = async ({
  tableId,
  seat,
  buyIn,
  txId,
  tableUrl,
}: JoinTablePayload & { tableUrl?: string }) => {
  let axiosInstance = axiosBaseApi;

  if (tableUrl) {
    axiosInstance = getAxiosInstance(tableUrl);
  }

  return axiosInstance.put<JoinTableResponse>(`/v1/poker-table/${tableId}/join`, { seat, buyIn, txId });
};

export const rebuyTableRest = async ({
  tableId,
  buyIn,
  txId,
  tableUrl,
}: Partial<JoinTablePayload> & { tableUrl?: string }) => {
  if (!tableUrl) {
    throw new Error('Table URL is required');
  }

  const axiosInstance = getAxiosInstance(tableUrl);

  return axiosInstance.put<void>(`/v1/poker-table/${tableId}/rebuy`, {
    amount: buyIn,
    txId,
  });
};

export const leaveTableRest = async ({ tableId, tableUrl }: TableIdPayload & { tableUrl?: string }) => {
  if (!tableUrl) {
    throw new Error('Table URL is required');
  }

  const axiosInstance = getAxiosInstance(tableUrl);

  return axiosInstance.post<void>(`/v1/poker-table/${tableId}/leave`);
};

export const joinWaitlistRest = async ({ tableId, tableUrl }: TableIdPayload & { tableUrl?: string }) => {
  if (!tableUrl) {
    throw new Error('Table URL is required');
  }

  const axiosInstance = getAxiosInstance(tableUrl);

  return axiosInstance.post<void>(`/v1/poker-table/${tableId}/waitlist/join`);
};

export const leaveWaitlistRest = async ({ tableId, tableUrl }: TableIdPayload & { tableUrl?: string }) => {
  if (!tableUrl) {
    throw new Error('Table URL is required');
  }

  const axiosInstance = getAxiosInstance(tableUrl);

  return axiosInstance.post<void>(`/v1/poker-table/${tableId}/waitlist/leave`);
};

export const sitInRest = async ({
  tableId,
  tableUrl,
  sitoutType,
}: TableIdPayload & { tableUrl?: string; sitoutType?: string }) => {
  if (!tableUrl) {
    throw new Error('Table URL is required');
  }

  const axiosInstance = getAxiosInstance(tableUrl);

  await axiosInstance.post<void>(`/v1/poker-table/${tableId}/sitin`, {
    sitoutType,
  });
};

interface SitOutTable {
  tableId: string;
  tableUrl: string;
}

export const sitInAllTablesRest = async (userTables: SitOutTable[]) => {
  userTables.forEach(async ({ tableId, tableUrl }) => {
    const axiosInstance = getAxiosInstance(tableUrl);
    await axiosInstance.post(`/v1/poker-table/${tableId}/sitin`);
  });
};

export const sitOutNextHandAllTablesRest = async (tableIds: SitOutTable[]) => {
  tableIds.forEach(async ({ tableId, tableUrl }) => {
    const axiosInstance = getAxiosInstance(tableUrl);
    await axiosInstance.post(`/v1/poker-table/${tableId}/sitout`);
  });
};

export const sitOutNextBBAllTablesRest = async (tableIds: SitOutTable[]) => {
  tableIds.forEach(async ({ tableId, tableUrl }) => {
    const axiosInstance = getAxiosInstance(tableUrl);
    await axiosInstance.post(`/v1/poker-table/${tableId}/sitout-next-bb`);
  });
};

export const sitOutNextBBRest = async ({ tableId, tableUrl }: { tableId: string; tableUrl: string }) => {
  if (!tableUrl) {
    throw new Error('Table URL is required');
  }

  const axiosInstance = getAxiosInstance(tableUrl);
  await axiosInstance.post<void>(`/v1/poker-table/${tableId}/sitout-next-bb`);
};

export const sitOutRest = async ({ tableId, tableUrl }: TableIdPayload & { tableUrl?: string }) => {
  if (!tableUrl) {
    throw new Error('Table URL is required');
  }

  const axiosInstance = getAxiosInstance(tableUrl);

  await axiosInstance.post<void>(`/v1/poker-table/${tableId}/sitout`);
};

export const sitOutAllTablesRest = async ({ sitoutType }: { sitoutType?: string }) => {
  const axiosInstance = axiosBaseApi;
  return await axiosInstance.post(`/v1/poker-table/sitout/all-tables`, { sitoutType });
};

export const autoTopUpRest = async ({
  tableId,
  autoTopUp,
  autoTopUpBuyIn,
  autoTopUpThreshold,
  tableUrl,
}: autoTopUpPayload & { tableUrl?: string }) => {
  if (!tableUrl) {
    throw new Error('Table URL is required');
  }

  const axiosInstance = getAxiosInstance(tableUrl);

  const response = await axiosInstance.put<void>(`/v1/poker-table/${tableId}/auto-top-up`, {
    autoTopUp,
    autoTopUpBuyIn,
    autoTopUpThreshold,
  });
  return response;
};

export const addAutoTopUpRest = async ({
  tableId,
  amount,
  tableUrl,
}: {
  tableId: string;
  amount: number;
  tableUrl?: string;
}) => {
  if (!tableUrl) {
    throw new Error('Table URL is required');
  }

  const axiosInstance = getAxiosInstance(tableUrl);

  return await axiosInstance.put<void>(`/v1/poker-table/${tableId}/auto-top-up/add`, { amount });
};

export const removeAutoTopUpRest = async ({ tableId, tableUrl }: { tableId: string; tableUrl?: string }) => {
  if (!tableUrl) {
    throw new Error('Table URL is required');
  }

  const axiosInstance = getAxiosInstance(tableUrl);
  return await axiosInstance.put<void>(`/v1/poker-table/${tableId}/auto-top-up/remove`);
};

export const getUserTablesRest = async (userId: string | undefined | null, tableUrl?: string | undefined) => {
  if (!userId) {
    throw new Error('User ID is required');
  }
  let axiosInstance = axiosBaseApi;

  if (tableUrl) {
    axiosInstance = getAxiosInstance(tableUrl);
  }

  return await axiosInstance.get<GetUserTablesResponse>(`/v1/user/${userId}/tables`);
};

export const getTableRest = async ({ tableId, tableUrl }: GetTablePayload & { tableUrl?: string }) => {
  let axiosInstance = axiosBaseApi;

  if (tableUrl) {
    axiosInstance = getAxiosInstance(tableUrl);
  }

  return await axiosInstance.get<TableState>(`/v1/poker-table/${tableId}`);
};

export const reserveSeat = async ({ tableId, seat, tableUrl }: ReserveTableSeatPayload & { tableUrl?: string }) => {
  let axiosInstance = axiosBaseApi;

  if (tableUrl) {
    axiosInstance = getAxiosInstance(tableUrl);
  }

  return axiosInstance.put<ReserveTableResponse>(`/v1/poker-table/${tableId}/reserve-seat`, { seat });
};

export const cancelReserveTableRest = async ({
  tableId,
  seat,
  tableUrl,
}: ReserveTableSeatPayload & { tableUrl?: string }) => {
  let axiosInstance = axiosBaseApi;

  if (tableUrl) {
    axiosInstance = getAxiosInstance(tableUrl);
  }

  return axiosInstance.put<ReserveTableResponse>(`/v1/poker-table/${tableId}/cancel-reserve-seat`, { seat });
};
