import { ChatGetChatsMsg, ChatGetMutedMsg, ChatRequestHistoryMsg, ChatSendMessageMsg } from './chatMessages';
import { ChatHistoryResponse, MutedUserResponse } from './chatServicesTypes';

export enum ChatActions {
  // CHAT
  SEND_MESSAGE = 'SEND_MESSAGE',
  REQUEST_HISTORY = 'REQUEST_HISTORY',
  ADD_USER = 'ADD_USER',
}

export enum ChatIncomingEvents {
  SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
  NEW_MESSAGE = 'NEW_MESSAGE',
  GET_CHATS = 'GET_CHATS',
  GET_MUTED = 'GET_MUTED',
  ADD_USER = 'ADD_USER',
}

export const chatConnectAction = (tableId: string, tableUrl: string): ChatRequestHistoryMsg => ({
  type: ChatActions.REQUEST_HISTORY,
  tableId,
  tableUrl,
});

export const sendMessage = (tableId: string, message: string): ChatSendMessageMsg => ({
  type: ChatActions.SEND_MESSAGE,
  tableId: tableId,
  data: {
    message: message,
  },
});

export const getChats = (tableId: string, response: ChatHistoryResponse[]): ChatGetChatsMsg => ({
  type: ChatIncomingEvents.GET_CHATS,
  data: { tableId: tableId, chats: response },
});

export const getMuted = (response: MutedUserResponse): ChatGetMutedMsg => ({
  type: ChatIncomingEvents.GET_MUTED,
  data: response,
});
