import axiosBaseApi from '../common/lib/axiosBaseApi';
import { ChatHistoryResponse, MutedUserResponse } from './chatServicesTypes';
import { getAxiosInstance } from '@/services/common/lib/axiosInstanceCache';

export const muteUserRest = async ({ tableId, userId }: { tableId: string; userId: string }) => {
  return await axiosBaseApi.post<MutedUserResponse>(`/v1/chat/mute`, {
    tableId,
    muted: userId,
  });
};

export const unmuteUserRest = async ({ tableId, userId }: { tableId: string; userId: string }) => {
  return await axiosBaseApi.post<MutedUserResponse>(`/v1/chat/unmute`, {
    tableId,
    muted: userId,
  });
};

export const getMutedUsersRest = async ({ tableId, tableUrl }: { tableId: string; tableUrl?: string }) => {
  let axiosInstance = axiosBaseApi;

  if (tableUrl) {
    axiosInstance = getAxiosInstance(tableUrl);
  }
  return await axiosInstance.get<MutedUserResponse>(`/v1/chat/table/${tableId}/muted-players`);
};

export const getChatsHistoryRest = async ({
  tableId,
  hoursAgo,
  tableUrl,
}: {
  tableId: string;
  hoursAgo: number;
  tableUrl: string;
}) => {
  let axiosInstance = axiosBaseApi;

  if (tableUrl) {
    axiosInstance = getAxiosInstance(tableUrl);
  }

  return await axiosInstance.get<ChatHistoryResponse[]>(`/v1/chat/table/${tableId}/hours/${hoursAgo}`);
};
