import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { replacePathParams } from '@/common/utils/utils';
import { WaitlistSeatReadyDto } from '@/services/lobby/lobbyState';
import { LobbyCancelSeatReady } from '@/services/lobby/lobbyActions';
import ROUTES from '@/routers/Routes';
import { ReducerState } from '@/services/common/lib/reducerState';
import TableStakeInfo from '@/components/TableStakeInfo/TableStakeInfo';
import sitout from '../../../assets/sitout.svg';
import ProgressBar from '../../../components/ProgressBar';
import useIsMobileView from '../../../hooks/useIsMobileView';

interface WaitlistSeatReadyProps {
  seatReady: WaitlistSeatReadyDto;
}

const WaitlistSeatReady: React.FC<WaitlistSeatReadyProps> = ({ seatReady }) => {
  const dispatch = useDispatch();
  const isMobileView = useIsMobileView();
  const [progress, setProgress] = useState(100);
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const tableState = useSelector((state: ReducerState) => state.tables?.[seatReady.tableId]);

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Check if the click is on the modal overlay (background) and not on the modal content
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  const handleClose = useCallback(async () => {
    dispatch(LobbyCancelSeatReady(seatReady.tableId));
  }, [seatReady.tableId, dispatch]);

  const tableInfo = tableState;

  useEffect(() => {
    const initialDiffMs = seatReady.timeout - Date.now();
    let animationFrameId: number;

    const updateProgress = () => {
      const diffMs = seatReady.timeout - Date.now();
      const diffSeconds = Math.floor(diffMs / 1000);
      setRemainingSeconds(diffSeconds);

      if (diffMs < 0) {
        handleClose();
        return;
      }

      setProgress((diffMs / initialDiffMs) * 100);
      animationFrameId = requestAnimationFrame(updateProgress);
    };

    animationFrameId = requestAnimationFrame(updateProgress);

    return () => cancelAnimationFrame(animationFrameId);
  }, [seatReady, handleClose]);

  const navigate = useNavigate();
  const isMobile = useIsMobileView();

  const handleJoinTable = async (tableId: string) => {
    // stack coming is the existing session stack
    const tableUrl = tableState?.url;
    if (tableUrl) {
      const url = new URL(replacePathParams(ROUTES.TABLE, { tableId }), window.location.origin);
      url.searchParams.set('seat', seatReady.seat.toString());
      url.searchParams.set('stack', (seatReady.stack ?? 0).toString());

      const isCurrentTable = window.location.pathname.includes(`/table/${tableId}`);

      if (isCurrentTable || isMobile) {
        dispatch(LobbyCancelSeatReady(tableId));
        navigate(url.pathname + url.search, { replace: true });
        return;
      }

      const width = window.innerWidth;
      const height = window.innerHeight;

      window.open(url.toString(), '_blank', `height=${height},width=${width}`);

      dispatch(LobbyCancelSeatReady(tableId));
    }
  };

  return (
    <div
      className="absolute inset-0 z-20 flex h-screen w-full items-center justify-center bg-black bg-opacity-30 backdrop-blur-md backdrop-filter"
      onClick={handleBackgroundClick}
    >
      <div
        className={`z-100 mx-auto gap-0 rounded-[50px] bg-phenom-background-modal-out ${
          isMobileView ? `mb-10 w-[310px] p-4` : `w-[500px] p-6`
        }`}
      >
        <div className="border-style-gradient mt-2 rounded-[30px]">
          <div className="modal-buy-in h-full text-white">
            <div className="mb-10 h-5/6">
              <div className="mb-4 flex flex-row">
                <h1 className="mr-auto text-left text-[24px] font-bold leading-[32px] text-white">It's your turn</h1>
                <img src={sitout} alt="close" onClick={() => handleClose()} className="modal-buy-in-close ml-auto" />
              </div>
              <div className="modal-text mb-4">
                A seat is open in the table{' '}
                <span className="px-1 font-bold text-white">
                  {tableInfo && (
                    <>
                      {tableInfo.name}
                      {' - '}
                      {`${tableInfo.currentVariant.bettingStructure} ${tableInfo.currentVariant.category}`}
                      <TableStakeInfo
                        fontSize="text-sm"
                        ante={tableInfo.ante}
                        smallBet={tableInfo.smallBet}
                        bigBet={tableInfo.bigBet}
                        smallBlind={tableInfo.smallBlind}
                        bigBlind={tableInfo.bigBlind}
                      />
                    </>
                  )}
                </span>
                and you are next in the Waitlist.
              </div>
              <ProgressBar progress={Math.floor(progress)} />
            </div>

            <div className="flex flex-row gap-4">
              <button
                onClick={() => {
                  handleJoinTable(seatReady.tableId);
                }}
                type="button"
                className={
                  'flex grow gap-[10px] rounded-[10px] bg-ph-purple p-[12px] px-[20px] text-center text-sm font-bold leading-5 text-white md:grow-0'
                }
              >
                Join Table {!isMobileView && <>({remainingSeconds})</>}
              </button>
              {/* Hidding this because we need to handle the case were we roll and we are alone in the waitlist */}
              {/* {!isMobileView && (
                <button
                  onClick={() => {
                    handleRoll(seatReady.tableId);
                  }}
                  type="button"
                  className={
                    "p-[12px] px-[20px] gap-[10px] rounded-[10px] text-white text-sm font-bold leading-5 text-center bg-ph-violet"
                  }
                >
                  Roll
                </button>
              )} */}

              <button
                onClick={handleClose}
                className="ml-auto grow gap-[10px] rounded-[10px] border border-[1px] border-ph-gray-dark p-[12px] px-[20px] text-center text-sm font-bold leading-5 text-ph-gray-dark md:grow-0"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitlistSeatReady;
