import { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import { getAccessToken } from '@privy-io/react-auth';

const authInterceptor = async (config: InternalAxiosRequestConfig) => {
  if (config.url?.includes('/v1/user/register')) {
    return config;
  }
  const authToken = await getAccessToken();

  if (!authToken) throw new Error('User not authenticated');

  if (!config.headers) {
    config.headers = new AxiosHeaders();
  }

  if (!config.headers['Authorization']) {
    config.headers['Authorization'] = `Bearer ${authToken}`;
  }

  return config;
};

const errorInterceptor = (error: any) => Promise.reject(error);

export { authInterceptor, errorInterceptor };
