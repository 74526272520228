import React, { createContext, useContext, useMemo, useReducer } from 'react';

// Define types for Action, Dispatch, ProviderProps, Reducer, and Store
export interface Action<ActionType> {
  type: ActionType;
  payload?: any;
}

export type Dispatch<ActionType> = React.Dispatch<Action<ActionType>>;

export interface ProviderProps<StoreType> {
  children: React.ReactNode;
  overrideInitialState?: Partial<StoreType>;
}

export type Reducer<ActionType, StoreType> = (state: StoreType, action: Action<ActionType>) => StoreType;

export interface Store<StoreType> {
  state: StoreType;
}

// Create the makeContextStore function
export default function makeContextStore<ActionType, StoreType>(
  name: string,
  reducer: Reducer<ActionType, StoreType>,
  initialState: StoreType,
): [
  (props: ProviderProps<StoreType>) => JSX.Element,
  () => Store<StoreType>,
  () => Dispatch<ActionType>,
  React.Context<Store<StoreType> | undefined>,
] {
  // Create contexts for state and dispatch
  const StoreContext = createContext<Store<StoreType> | undefined>(undefined);
  const DispatchContext = createContext<Dispatch<ActionType> | undefined>(undefined);

  // Set displayName for debugging purposes
  StoreContext.displayName = `${name}StoreContext`;
  DispatchContext.displayName = `${name}DispatchContext`;

  const StoreProvider = ({ children, overrideInitialState = {} }: ProviderProps<StoreType>) => {
    // Compute the initial state by merging the provided initial state and any overrides
    const combinedInitialState = { ...initialState, ...overrideInitialState };

    // Use the useReducer hook to manage the state with the provided reducer and combined initial state
    const [state, dispatch] = useReducer(reducer, combinedInitialState);

    // Memoize the state value to prevent unnecessary re-renders
    // Only recompute the value if the state changes
    const value = useMemo(() => ({ state }), [state]);
    // Provide the state and dispatch contexts to the component tree
    // The nested context providers allow us to separate the state and dispatch functions
    // This separation helps minimize re-renders in components that only consume the dispatch function
    return (
      <DispatchContext.Provider value={dispatch}>
        <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
      </DispatchContext.Provider>
    );
  };

  function useStore(): Store<StoreType> {
    const context = useContext(StoreContext);
    if (!context) {
      throw new Error(`${name}Store must be used within its corresponding StoreProvider`);
    }
    return context;
  }

  function useDispatch(): Dispatch<ActionType> {
    const dispatch = useContext(DispatchContext);
    if (!dispatch) {
      throw new Error(`${name}Dispatch must be used within its corresponding StoreProvider`);
    }
    return dispatch;
  }

  return [StoreProvider, useStore, useDispatch, StoreContext];
}
