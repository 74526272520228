import styles from './CurrencyIcon.module.css';

import { ReactComponent as GreenTetherIcon } from '@/assets/tokens/tether-green.svg';
import { ReactComponent as WhiteTetherIcon } from '@/assets/tokens/tether-white.svg';
import { twMerge } from 'tailwind-merge';

export enum TetherIconColorOptions {
  green = 'green',
  white = 'white',
}

const CurrencyIcon = ({
  fill = TetherIconColorOptions.green,
  size = 2,
  className = '',
}: {
  fill?: string | undefined;
  size?: number;
  className?: string;
}) => {
  const classes = twMerge(styles.tetherIcon, `!w-[${size}em] !h-[${size}em]`, className);

  if (fill === TetherIconColorOptions.green) {
    return <GreenTetherIcon className={classes} />;
  }

  return <WhiteTetherIcon className={classes} />;
};

export default CurrencyIcon;
