import React from 'react';
import './LoadingSpinner.module.css';
import { twMerge } from 'tailwind-merge';

const LoadingSpinner = ({
  size = '10',
  fullWidth = true,
  borderColor = 'border-ph-purple',
  className = '',
}: {
  size?: string;
  fullWidth?: boolean;
  borderColor?: string;
  className?: string;
}) => {
  return (
    <div className={twMerge('flex h-full items-center justify-center', fullWidth && 'w-full', className)}>
      <div className={`animate-spin rounded-full border-b-[1px] border-t-[1px] ${borderColor} w-${size} h-${size}`} />
    </div>
  );
};

export default LoadingSpinner;
