import { PlayerActions, TableIncomingEvents } from './tableActions';
import { TableActionPerformedMsg, TablePlayerTurnMsg } from './tableMessages';
import { Operations } from './tableOperations';
import { EventSounds, GetEventSound } from './tableTypes';

export const getTableEventSound = (action: TableActionPerformedMsg | TablePlayerTurnMsg): EventSounds | undefined => {
  switch (action.type) {
    case TableIncomingEvents.ACTION_PERFORMED:
      return getActionPerformedSound(action);
    case TableIncomingEvents.PLAYER_TURN:
      return getPlayerTurnSound(action);
    default:
      return undefined;
  }
};

const getActionPerformedSound: GetEventSound<TableActionPerformedMsg> = (tableMessage) => {
  if (tableMessage.data?.lastOperation?.cards?.length === 3) return EventSounds.FLOP;

  switch (tableMessage.data?.lastOperation?.subtype) {
    case Operations.ChipsPulling: // also contains winning player seat
      return EventSounds.CHIPS_PULLING;
    case Operations.ChipsPushing:
      return EventSounds.CHIPS_PUSHING;
    case Operations.HoleDealing:
      return EventSounds.DEAL;
    case Operations.BoardDealing:
      return EventSounds.BOARD_DEAL;
  }

  switch (tableMessage.data?.lastOperation?.action) {
    case PlayerActions.RAISE:
      return EventSounds.RAISE;
    case PlayerActions.BET:
      return EventSounds.BET;
    case PlayerActions.FOLD:
      return EventSounds.FOLD;
    case PlayerActions.CALL:
      return EventSounds.CALL;
    case PlayerActions.CHECK:
      return EventSounds.CHECK;
    case PlayerActions.COMPLETE:
      return EventSounds.RAISE; // TODO: add sound for complete
    case PlayerActions.POST_BRING_IN:
      return EventSounds.CALL; // TODO: add sound for bring in
  }

  return undefined;
};

const getPlayerTurnSound: GetEventSound<TablePlayerTurnMsg> = (tableMessage) => {
  const secondWarningTime = 8;
  const { timer, timeRemaining } = tableMessage.data;

  if (Number(timer) === timeRemaining) return EventSounds.FIRST_WARNING;

  if (timeRemaining === secondWarningTime) return EventSounds.SECOND_WARNING;

  return undefined;
};
