enum ROUTES {
  LOBBY = '/lobby',
  FRIENDS = '/friends',
  FRIENDS_REQUEST = '/friends-request',
  TABLE = '/table/:tableId',
  KYC = '/kyc',
  RESET_PASSWORD = '/reset-password',
  REGISTER = '/register',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  MY_HUB = '/my-hub',
  AVATURN = '/avatar',
  REPLAY = '/replay/hand/:handId/table/:tableId',
  PRODUCT_BOARD = '/roadmap',
  AGENT_DASHBOARD = '/agent-dashboard',
  DEFAULT = '*',
  HAND_HISTORY = '/hand-history',
}

export default ROUTES;
