// hooks/useIsMobileView.ts
import { useMemo } from 'react';
import { useMobileContext } from '../contexts/MobileViewContext';
import BREAKPOINTS from '@/common/constant/breakpoints';

const useIsMobileView = (options?: { breakpoint?: number; checkForUserAgent?: boolean }): boolean => {
  const { breakpoint = BREAKPOINTS.md, checkForUserAgent = true } = options || {};
  const mobileViewContext = useMobileContext();

  const isMobile = useMemo(() => {
    if (checkForUserAgent) return mobileViewContext.isMobileDevice;
    else return mobileViewContext.breakpoints[breakpoint];
  }, [breakpoint, checkForUserAgent, mobileViewContext]);
  return isMobile;
};

export default useIsMobileView;
