import { LobbyState } from './lobbyState';
import { LobbyIncomingEvents, LobbyInternalEvents } from './lobbyActions';
import { LobbyIncomingEventMsg } from './lobbyMessages';

const initialState: LobbyState = {
  tables: [],
  userTables: [], // TODO : TO BE MOVED TO USER REDUCER
  isConnected: true,
  playersOnline: 0,
  activeCashTables: 0,
  activeCashTournaments: 0,
  tableBuyIn: null,
  waitlistSeatReady: undefined,
  visitorIdToKeep: undefined,
};

function lobbyReducer(state = initialState, action: LobbyIncomingEventMsg): LobbyState {
  switch (action.type) {
    case LobbyInternalEvents.LOBBY_CONNECTED:
      return { ...state, isConnected: true };
    case LobbyInternalEvents.LOBBY_DISCONNECTED:
      return { ...state, isConnected: false };
    case LobbyInternalEvents.LOBBY_REFRESHED:
      return action.data;
    case LobbyInternalEvents.OPEN_BUY_IN: {
      return {
        ...state,
        tableBuyIn: { ...action.data, open: true, isRebuy: action.data.isRebuy },
      };
    }
    case LobbyInternalEvents.UPDATE_BUY_IN: {
      return {
        ...state,
        tableBuyIn: { ...state.tableBuyIn, ...action.data },
      };
    }

    case LobbyIncomingEvents.SEAT_READY: {
      const waitlistSeatReady = action.data;

      return {
        ...state,
        waitlistSeatReady,
      };
    }
    case LobbyInternalEvents.CANCEL_SEAT_READY: {
      return {
        ...state,
        waitlistSeatReady: undefined,
      };
    }
    case LobbyInternalEvents.CLOSE_BUY_IN: {
      return {
        ...state,
        tableBuyIn: null,
      };
    }
    case LobbyIncomingEvents.DUPLICATE_LOGIN: {
      return {
        ...state,
        visitorIdToKeep: action.data.visitorId,
      };
    }

    default:
      return state;
  }
}

export default lobbyReducer;
