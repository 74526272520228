import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    gtag: (command: string, action: string, params?: Record<string, any>) => void;
  }
}

export const GoogleAnalytics = () => {
  const location = useLocation();
  const isProd = process.env.REACT_APP_NODE_ENV === 'production';

  useEffect(() => {
    if (isProd && typeof window.gtag !== 'undefined') {
      window.gtag('config', 'G-243Q5BNMMT', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location, isProd]);

  return null;
};
