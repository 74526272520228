import { ChatIncomingEvents } from '../chat/chatActions';
import { ChatMessage } from '../chat/chatServicesTypes';
import { ChatState } from './ChatState';
import { ChatIncomingEventMsg } from './chatMessages';
import { SystemMessages } from './service/systemTypes';

type ChatStates = Record<string, ChatState>;

const initialState: ChatStates = {};

const SYSTEM_MESSAGES_LENGTH = 200;

function chatReducer(state = initialState, action: ChatIncomingEventMsg): ChatStates {
  let tableChat: ChatState;
  let chatMessages: (ChatMessage | SystemMessages)[];
  let systemMessages: SystemMessages[];
  let muted: string[];

  switch (action.type) {
    case ChatIncomingEvents.GET_CHATS: {
      tableChat = state[action.data.tableId];

      if (tableChat && tableChat.muted) {
        muted = tableChat.muted;
      } else {
        muted = [];
      }

      return {
        ...state,
        [action.data.tableId]: {
          ...tableChat,
          chatMessages: [...action.data.chats.filter((chat) => chat.userId && !muted.includes(chat.userId))],
          muted,
        },
      };
    }

    case ChatIncomingEvents.GET_MUTED: {
      tableChat = state[action.data.tableId];

      if (!tableChat) {
        tableChat = {
          chatMessages: [],
          systemMessages: [],
          muted: [],
        };
      }

      muted = action.data.mutedUserIds;

      if (tableChat.chatMessages) {
        chatMessages = [...tableChat.chatMessages.filter((chat) => chat.userId && !muted.includes(chat.userId))];
      } else {
        chatMessages = [];
      }

      return {
        ...state,
        [action.data.tableId]: {
          ...tableChat,
          chatMessages,
          muted,
        },
      };
    }

    case ChatIncomingEvents.NEW_MESSAGE: {
      tableChat = state[action.data.tableId];

      if (!tableChat) {
        tableChat = {
          chatMessages: [],
          systemMessages: [],
          muted: [],
        };
      }

      if (tableChat.muted.includes(action.data.userId)) {
        return state;
      }

      chatMessages = [...tableChat.chatMessages, action.data];

      return {
        ...state,
        [action.data.tableId]: {
          ...tableChat,
          chatMessages,
          muted: tableChat.muted,
        },
      };
    }

    case ChatIncomingEvents.SYSTEM_MESSAGE: {
      tableChat = state[action.data.tableId];

      if (!tableChat) {
        tableChat = {
          chatMessages: [],
          systemMessages: [],
          muted: [],
        };
      }

      systemMessages = [action.data].slice(0, 100);
      if (tableChat.systemMessages) {
        // always return the last SYSTEM_MESSAGES_LENGTH messages
        systemMessages = [...tableChat.systemMessages, ...systemMessages].slice(-SYSTEM_MESSAGES_LENGTH);
      }
      return {
        ...state,
        [action.data.tableId]: {
          ...tableChat,
          systemMessages,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default chatReducer;
