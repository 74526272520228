import { call, delay, fork, put } from 'redux-saga/effects';
import { postTableInit } from '../queries/usePostTableInit';
import { getTableRest } from '../tableService';
import { tableStateUpdate } from '../tableActions';
import config from '@/common/config';

function* watchTableInit(action: { type: string; tableId: string }) {
  yield fork(tableInitSaga, action.tableId);
  yield fork(getTable, action.tableId);
}

function* tableInitSaga(tableId: string): Generator<any, void, any> {
  let retries = 0;
  const maxRetries = 20;
  let initResponse;

  while (retries < maxRetries) {
    try {
      initResponse = yield call(postTableInit, tableId);

      if (initResponse.data?.url) {
        // only success if we have a table url, otherwise retry
        break; // If the call succeeds, break out of the loop
      } else {
        retries++;
        if (retries === maxRetries) {
          //   yield put(tableInitFailure(tableId, error));
          throw new Error('Max retries reached. Table initialization failed.');
        }
        yield delay(5000); // retry every 5 seconds
      }
    } catch (_error: unknown) {
      console.error('Max retries reached. Table initialization failed.');
      //   yield put(tableInitFailure(tableId, error));
      return;
    }
  }

  yield put(
    tableStateUpdate(tableId, {
      ...initResponse.data,
      contractAddress: initResponse.data?.contractAddress ?? config.contracts.tables,
      tableId,
    }),
  );
}

function* getTable(tableId: string): Generator<any, void, any> {
  const tableResponse = yield call(getTableRest, { tableId });

  if (tableResponse.data.url) {
    return;
  }

  const tableData = { ...tableResponse.data, tableId };
  console.log('GET tableData', tableData);
  yield put(tableStateUpdate(tableId, tableData));
}

export default watchTableInit;
