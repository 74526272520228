import { useEffect } from 'react';

// Function to clear all service worker registrations and caches
const clearServiceWorkers = async () => {
  try {
    // Get all service worker registrations
    const registrations = await navigator.serviceWorker.getRegistrations();

    // Unregister each registration
    for (const registration of registrations) {
      await registration.unregister();
    }

    // Clear all caches
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)));

    console.log('Service workers and caches cleared successfully');
  } catch (error) {
    console.error('Error clearing service workers and caches:', error);
  }
};

// Custom React component to clear service workers and caches
const UnregisterServiceWorker = () => {
  useEffect(() => {
    clearServiceWorkers();
  }, []);

  return null; // This component doesn't render anything
};

export default UnregisterServiceWorker;
