import CurrencyIcon, { TetherIconColorOptions } from '../CurrencySymbolMask/CurrencyIcon';
import { formatCurrency } from '@/pages/table/utils/utils';

interface TableStakeInfoProps {
  smallBet?: number;
  bigBet?: number;
  ante?: number;
  smallBlind?: number;
  bigBlind?: number;
  fontSize?: string;
  color?: string;
}

const TableStakeInfo: React.FC<TableStakeInfoProps> = ({
  smallBet,
  bigBet,
  ante,
  smallBlind,
  bigBlind,
  fontSize = '',
  color = TetherIconColorOptions.green,
}) => {
  const formattedSmallBet = formatCurrency(smallBet, { shortFormat: true });
  const formattedBigBet = formatCurrency(bigBet, { shortFormat: true });
  const formattedAnte = formatCurrency(ante, { shortFormat: true });
  const formattedSmallBlind = formatCurrency(smallBlind, { shortFormat: true });
  const formattedBigBlind = formatCurrency(bigBlind, { shortFormat: true });

  const blind = () => {
    const isSmallBet = smallBet && smallBet > 0;

    return (
      <>
        <span className="flex flex-row items-center">
          <span className="flex flex-row items-center gap-0.5">
            <CurrencyIcon fill={color} />
            {isSmallBet ? formattedSmallBet : formattedSmallBlind}
          </span>
          <span className="mx-2 font-bold">/</span>
          <span className="flex flex-row items-center gap-0.5">{isSmallBet ? formattedBigBet : formattedBigBlind}</span>
        </span>
      </>
    );
  };

  return (
    <span className={`mx-1 flex items-center ${fontSize ? fontSize : 'text-xs xl:text-sm'}`}>
      {blind()}
      {ante && ante > 0 && (
        <>
          <span className="mx-2">-</span>
          <span className="flex flex-row items-center gap-0.5">{formattedAnte} ante</span>
        </>
      )}
    </span>
  );
};

export default TableStakeInfo;
