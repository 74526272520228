import { polygon } from 'viem/chains';
import { Config } from './config.interface';

export const productionConfig: Config = {
  environment: 'production',
  smartWalletEnabled: true,
  contracts: {
    usdt: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    phnm: '0x35d4F6D876d6c14775DF8A74b8dE3A699cF458AC',
    tables: '0xC10e197E783394D08310F5013F05362a64a891C4',
    redemption: '0x1D26bA1e70F19bd1FBdE67B38194e49f910f5f02',
    redemptionVault: '0xd251fBDF90b8FEeaEA13F5425a186F56c1F3E7E0',
  },
  chain: polygon,
  blockExplorerUrl: 'https://polygonscan.com/',
  usdtDecimals: 6,
  phnmDecimals: 18,
};
