import {
  take,
  call,
  put,
  cancelled,
  takeEvery,
  fork,
  CallEffect,
  PutEffect,
  ChannelTakeEffect,
  CancelledEffect,
  ForkEffect,
  TakeEffect,
} from 'redux-saga/effects';
import { Socket, io } from 'socket.io-client';
import { EventChannel, eventChannel } from 'redux-saga';
import { LobbyIncomingEvents, LobbyInternalEvents, LobbyRequest } from '../lobbyActions';
import { LobbyIncomingEventMsg } from '../lobbyMessages';
import { lobbyRefresh } from './lobbySagas';
import { getAccessToken } from '@privy-io/react-auth';

function createSocketChannel(socket: Socket) {
  return eventChannel((emit) => {
    const handleEvent = (eventType: LobbyIncomingEvents) => (data: any) => {
      emit({ type: eventType, data });
    };

    Object.values(LobbyIncomingEvents).forEach((eventType) => {
      socket.on(eventType, handleEvent(eventType));
    });

    return () => {
      Object.values(LobbyIncomingEvents).forEach((eventType) => {
        socket.off(eventType, handleEvent(eventType));
      });
    };
  });
}

function handleSendSocketMessage(socket: Socket, message: LobbyIncomingEventMsg) {
  if (socket && socket.connected) {
    socket.emit(message.type, message.data);
  }
}

function* catchRequestToServer(socket: Socket) {
  yield takeEvery(LobbyRequest.LOBBY_REFRESH, handleSendSocketMessage, socket);
}

function* handleSocketConnection(): Generator<
  | CallEffect
  | PutEffect
  | ForkEffect
  | TakeEffect
  | ChannelTakeEffect<LobbyInternalEvents>
  | ChannelTakeEffect<LobbyIncomingEventMsg>
  | CancelledEffect,
  void,
  any // TODO : To specify Next Type i need to refactor the funcion
> {
  const socket = io(`${process.env.REACT_APP_SOCKET_GATEWAY}`, {
    transports: ['websocket'],
    auth: (cb) => {
      getAccessToken().then((token) => cb({ token }));
    },
    withCredentials: true,
  });

  socket.on('expired_auth_token', () => {
    console.log('auth_error');
    socket.disconnect().connect();
  });

  // Disconnection event
  socket.on('disconnect', () => {
    // TODO : HANDLE RECONNECTION ?
    console.log('disconnected');
  });

  socket.on('error', () => {
    // TODO : Do we advise the user that there is a connection issue?
    // socket.connect();
  });

  socket.on('connect', () => {
    console.log('conntected to lobby socket');
  });

  const channel: EventChannel<LobbyInternalEvents> = yield call(createSocketChannel, socket);

  try {
    // Start watching for send message actions
    yield fork(catchRequestToServer, socket);

    while (true) {
      const message = yield take(channel);
      yield put(message);
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
      socket.disconnect();
    }
  }
}

function* lobbyRootSaga() {
  yield fork(handleSocketConnection); // TODO : Connection is not going to work because auth guard
  yield takeEvery(LobbyRequest.LOBBY_REFRESH, lobbyRefresh);
}
export default lobbyRootSaga;
