import { call, put } from 'redux-saga/effects';
import { ChatRequestHistoryMsg } from '../chatMessages';
import { getMutedUsersRest } from '../chatService';
import { getMuted } from '../chatActions';
import { AxiosResponse } from 'axios';

export default function* getMutedUsersSaga(action: ChatRequestHistoryMsg) {
  try {
    const { tableUrl } = action;
    const response: AxiosResponse = yield call(getMutedUsersRest, {
      tableId: action.tableId,
      tableUrl,
    });
    yield put(getMuted(response.data));
  } catch (error) {
    console.error('Error in fetchChatsSaga:', error);
    // Handle the error based on your application's requirements
  }
}
