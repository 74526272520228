import { UserCreatePrivy } from '@/features/auth/interfaces/privy.interface';
import { User } from '@/services/user/userTypes';

// they have direct effect on state
export enum AuthEvents {
  AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS', // Indicates successful authentication
  AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE', // Indicates a failure in the authentication process
  LOGOUT = 'LOGOUT', // Indicates a failure in the authentication process
  REGISTER_USER = 'REGISTER_USER', // Triggers the action to register a new user in the BE
  UPDATE_USER = 'UPDATE_USER', // Triggers the action to update user data on the BE
  FETCH_AUTHENTICATED_USER_DATA = 'FETCH_AUTHENTICATED_USER_DATA', // Triggers the action to fetch user data from the BE
  USER_DATA_LOADED = 'USER_DATA_LOADED', // Indicates that the user data has been successfully loaded
  SET_LOADING = 'SET_LOADING',
  REGISTER_ERROR = 'REGISTER_ERROR',
  UPDATE_SETTINGS = 'UPDATE_SETTINGS',
  UPDATE_LOBBY_FILTERS = 'UPDATE_LOBBY_FILTERS',
}
// Assuming you have a type for AuthRequest similar to LobbyRequest
export const authActionCreator = (
  type: AuthEvents, // Use your AuthEvents enum here
  payload?: any, // Specify a more specific type based on what data your auth actions expect
) => {
  return {
    type,
    payload,
  };
};

export interface RegisterUserAction {
  type: AuthEvents.REGISTER_USER;
  payload: UserCreatePrivy;
}

export interface UpdateUserAction {
  type: AuthEvents.UPDATE_USER;
  payload: User;
}
