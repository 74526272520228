import React, { useState, useEffect } from 'react';
import styles from './LogoLoadingSpinner.module.css';

import clubsLoader from '@/assets/loading-spinner/clubs-loader.svg';
import diamondsLoader from '@/assets/loading-spinner/diamonds-loader.svg';
import heartsLoader from '@/assets/loading-spinner/hearts-loader.svg';
import spadesLoader from '@/assets/loading-spinner/spades-loader.svg';
import phenomLoader from '@/assets/loading-spinner/loader-phenom.svg';
import { twMerge } from 'tailwind-merge';

const suits = [clubsLoader, diamondsLoader, heartsLoader, spadesLoader];
const logo = phenomLoader;

const LogoLoadingSpinner = ({ size = '20' }: { size?: string }) => {
  const [currentSuit, setCurrentSuit] = useState(0);
  const [isLogo, setIsLogo] = useState(true);
  const [scaleClass, setScaleClass] = useState(styles.grow);

  useEffect(() => {
    const interval = setInterval(() => {
      setScaleClass(styles.shrink);
      setTimeout(() => {
        setIsLogo((prev) => !prev);
        if (!isLogo) {
          setCurrentSuit((prev) => (prev + 1) % suits.length);
        }
        setScaleClass(styles.grow);
        setTimeout(() => {
          setScaleClass(styles.bounce);
          setTimeout(() => {
            setScaleClass(styles.max);
          }, 50); // time for the bounce effect
        }, 250); // time for the full growth
      }, 250); // time to wait before changing the image
    }, 500); // total cycle time

    return () => clearInterval(interval);
  }, [isLogo]);

  const sizeClass = `w-${size} h-${size}`;

  return (
    <div className="flex h-full items-center justify-center">
      <div className={`relative ${sizeClass}`}>
        <img
          src={isLogo ? logo : suits[currentSuit]}
          alt="Loading"
          className={twMerge('absolute left-0 top-0 h-full w-full', `${scaleClass}`)}
        />
      </div>
    </div>
  );
};

export default LogoLoadingSpinner;
