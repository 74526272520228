import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse, isAxiosError } from 'axios';
import { registerUser, updateRegisteredUser } from '@/services/user/user.service';
import { User } from '@/services/user/userTypes';

import { AuthEvents, RegisterUserAction, UpdateUserAction } from '../authActions';
import { me } from '../authService'; // Hypothetical API calls

function* handleRegisterUser(action: RegisterUserAction) {
  try {
    console.log('handleRegisterUser', { action });
    const userData: AxiosResponse = yield call(registerUser, action.payload);
    yield put({
      type: AuthEvents.USER_DATA_LOADED,
      payload: { ...userData.data, registerError: undefined },
    });
  } catch (error) {
    if (isAxiosError(error)) {
      yield put({
        type: AuthEvents.REGISTER_ERROR,
        payload: { registerError: error.response?.data.message },
      });
    }
  }
}
function* handleUpdateUser(action: UpdateUserAction) {
  try {
    const { id: _, ...updateUserData } = action.payload;
    const response: AxiosResponse = yield call(updateRegisteredUser, updateUserData);
    yield put({ type: AuthEvents.USER_DATA_LOADED, payload: response.data });
  } catch (_error) {
    // Handle error
  }
}
function* handleFetchUserData() {
  yield put({
    type: AuthEvents.SET_LOADING,
    payload: true,
  });
  try {
    const userData: AxiosResponse<User> = yield call(me);
    yield put({ type: AuthEvents.USER_DATA_LOADED, payload: userData.data });
  } catch (_error) {
    // Handle error
  } finally {
    yield put({
      type: AuthEvents.SET_LOADING,
      payload: false,
    });
  }
}

function* authRootSaga() {
  // yield takeLatest(AuthEvents.USER_AUTHENTICATED, handleAuthentication);
  yield takeLatest(AuthEvents.FETCH_AUTHENTICATED_USER_DATA, handleFetchUserData);
  yield takeLatest(AuthEvents.REGISTER_USER, handleRegisterUser);
  yield takeLatest(AuthEvents.UPDATE_USER, handleUpdateUser);
}

export default authRootSaga;
