import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { me } from '@/services/auth/authService';
import { User } from '@/services/user/userTypes';
import { AuthEvents } from '@/services/auth/authActions';

export const useMe = (options?: { enabled?: boolean; userId?: string }) => {
  const dispatch = useDispatch();

  return useQuery<AxiosResponse<User>, Error>({
    queryKey: ['user', options?.userId], // Use userId from options
    queryFn: me,
    staleTime: 30 * 1000, // 30 seconds
    gcTime: 60 * 1000, // 1 minute
    enabled: (options?.enabled ?? true) && !!options?.userId, // Only enable if we have a userId
    select: (response) => {
      dispatch({
        type: AuthEvents.USER_DATA_LOADED,
        payload: response.data,
      });
      return response;
    },
  });
};
