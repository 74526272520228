import { useState, useEffect } from 'react';

const useIsPortraitView = () => {
  const [isPortraitView, setIsPortraitView] = useState(window.matchMedia('(orientation: portrait)').matches);

  useEffect(() => {
    const handleOrientationChange = () => setIsPortraitView(window.matchMedia('(orientation: portrait)').matches);

    const mediaQuery = window.matchMedia('(orientation: portrait)');
    mediaQuery.addEventListener('change', handleOrientationChange);

    return () => mediaQuery.removeEventListener('change', handleOrientationChange);
  }, []);

  return isPortraitView;
};

export default useIsPortraitView;
