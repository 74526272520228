import { twMerge } from 'tailwind-merge';
import useIsMobileView from '../../../hooks/useIsMobileView';
import Button, { ButtonVariants } from '../../../components/new-design/Button';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../routers/Routes';
import { useDispatch } from 'react-redux';
import { AuthEvents, authActionCreator } from '@/services/auth/authActions';
import { acceptGreeting } from '@/services/user/user.service';

interface KycApprovedProps {
  userId: string;
}

const KycApproved = ({ userId }: KycApprovedProps) => {
  const isMobileView = useIsMobileView();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoToLobby = async () => {
    await acceptGreeting(userId);
    dispatch(authActionCreator(AuthEvents.FETCH_AUTHENTICATED_USER_DATA));
    navigate(ROUTES.LOBBY, { replace: true });
  };

  const handleEditAvatar = async () => {
    await acceptGreeting(userId);
    navigate(ROUTES.AVATURN, { replace: true });
  };

  return (
    <div className="m-4 flex h-full items-center justify-center">
      <div className="flex flex-col items-center gap-7 text-center">
        <div className="flex flex-col items-center justify-center gap-1">
          <h1 className="text-2xl text-ph-gray-white">Successfully Verified!</h1>
          <p className="text-base font-normal text-ph-gray-silver">
            Welcome to <span className="text-ph-primary-violet">Phenom Poker</span> - innovative community at the
            forefront of the revolution in online gaming.
          </p>

          <p className="text-base font-normal text-ph-gray-silver">
            Before proceding to the Lobby, would you like to create your avatar?
          </p>
        </div>

        <div className={twMerge('flex w-full items-center justify-center gap-2', isMobileView && 'flex-col')}>
          <Button
            type="button"
            variant={ButtonVariants.secondary}
            onClick={handleEditAvatar}
            className="border-ph-primary-violet bg-ph-background-dark capitalize text-ph-primary-violet"
            fullWidth
          >
            Create Avatar
          </Button>

          <Button type="button" onClick={handleGoToLobby} fullWidth>
            Skip
          </Button>
        </div>
      </div>
    </div>
  );
};

export default KycApproved;
