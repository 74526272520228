import './App.css';
import { Provider } from 'react-redux';
import { AppRouter } from './routers/AppRouter';
import { store } from './services/app/store';
import { AccountAbstractionProvider } from '@/features/account-abstraction/store/AccountAbstractionContext';
import { MobileViewProvider } from './contexts/MobileViewContext';

const App = () => {
  return (
    <Provider store={store}>
      <MobileViewProvider>
        <AccountAbstractionProvider>
          <AppRouter />
        </AccountAbstractionProvider>
      </MobileViewProvider>
    </Provider>
  );
};

export default App;
