import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { formatCurrency } from '@/pages/table/utils/utils';

export const currencySymbol = (): string => {
  return `₮`;
};

export interface TableStakeInfo {
  smallBet?: number;
  bigBet?: number;
  ante?: number;
  smallBlind?: number;
  bigBlind?: number;
}

export const createStakeString = (table: TableStakeInfo) => {
  const smallBet = formatCurrency(table.smallBet);
  const bigBet = formatCurrency(table.bigBet);
  const ante = formatCurrency(table.ante);
  const smallBlind = formatCurrency(table.smallBlind);
  const bigBlind = formatCurrency(table.bigBlind);

  const blind =
    table.smallBet && table.smallBet > 0
      ? `${currencySymbol()}${smallBet} / ${currencySymbol()}${bigBet} `
      : `${currencySymbol()}${smallBlind} / ${currencySymbol()}${bigBlind}`;

  return `${blind} ${table.ante && table.ante > 0 ? `w ${currencySymbol()}${ante} ante` : ''}`;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type Params = Record<string, string | number>;

type ReplacePathParamsFunction = (path: string, params: Params, prefix?: string) => string;

export const replacePathParams: ReplacePathParamsFunction = (path, params, prefix = ':') => {
  let newPath = path;

  Object.entries(params).forEach(([key, value]) => {
    newPath = newPath.replace(prefix + key, String(value));
  });

  return newPath;
};

export const formatWalletAddress = (walletAddress: `0x${string}` | undefined) =>
  walletAddress ? `${walletAddress.slice(0, 5)}…${walletAddress.slice(-4)}` : '';
