import { ReducerState } from '../../services/common/lib/reducerState';
import { useSelector } from 'react-redux';
import WaitlistSeatReady from './components/WaitlistSeatReady';
import { LobbyRequest } from '../../services/lobby/lobbyActions';
import { useEffect } from 'react';
import useIsMobileView from '../../hooks/useIsMobileView';
import { store } from '../../services/app/store';

const Modals = () => {
  const isMobileView = useIsMobileView();

  const waitlistSeatReady = useSelector((state: ReducerState) => state.lobby.waitlistSeatReady);

  useEffect(() => {
    const itemStr = localStorage.getItem('LogIn');
    if (itemStr) {
      localStorage.removeItem('LogIn');
      const item = JSON.parse(itemStr);
      const now = new Date().getTime();

      if (now < item.expiry) {
        if (isMobileView) {
          store.dispatch({ type: LobbyRequest.CONNECT_ALL_TABLES });
        } else {
          store.dispatch({ type: LobbyRequest.OPEN_ALL_TABLES });
        }
      }
    }
  }, [isMobileView]);

  if (waitlistSeatReady) return <WaitlistSeatReady seatReady={waitlistSeatReady} />;
};

export default Modals;
