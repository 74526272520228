// Semantics request to be made to lobby context on server, whether WS or APIs
// Can trigger side effects

export enum LobbyRequest {
  LOBBY_REFRESH = 'LOBBY_REFRESH',
  OPEN_ALL_TABLES = 'OPEN_ALL_TABLES',
  CONNECT_ALL_TABLES = 'CONNECT_ALL_TABLES',
}

// Incoming server expected events to update lobby.
// they have direct effect on state
export enum LobbyIncomingEvents {
  SEAT_READY = 'SEAT_READY',
  CANCEL_SEAT_READY = 'CANCEL_SEAT_READY',
  DUPLICATE_LOGIN = 'DUPLICATE_LOGIN',
}

export enum LobbyInternalEvents {
  LOBBY_CONNECTED = 'LOBBY_CONNECTED',
  LOBBY_DISCONNECTED = 'LOBBY_DISCONNECTED',
  LOBBY_REFRESHED = 'LOBBY_REFRESHED',
  TABLE_REFRESHED = 'TABLE_REFRESHED',
  OPEN_BUY_IN = 'OPEN_BUY_IN',
  CLOSE_BUY_IN = 'CLOSE_BUY_IN',
  UPDATE_BUY_IN = 'UPDATE_BUY_IN',
  CANCEL_SEAT_READY = 'CANCEL_SEAT_READY',
}

// Action Creators: creates the message to be dispatched with socket in the proper way
export const lobbyConnectAction = () => ({
  type: LobbyInternalEvents.LOBBY_CONNECTED,
});

// Action Creators: creates the message to be dispatched with socket in the proper way
export const lobbyDisconnectAction = () => ({
  type: LobbyInternalEvents.LOBBY_DISCONNECTED,
});

// Action Creators: creates the message to be dispatched with socket in the proper way
export const lobbyRefreshAction = (_type: LobbyRequest.LOBBY_REFRESH) => ({
  type: LobbyRequest.LOBBY_REFRESH,
});

export const lobbyActionRest = (type: LobbyRequest, data?: { tableId: string }) => {
  return {
    type: type,
    data: data ? { tableId: data.tableId } : data,
  };
};

export const LobbyTableOpenBuyInAction = ({
  tableId,
  seat,
  stack,
  seatReserved = false,
  isRebuy = false,
  showTimer = true,
  sitOutOnCancel = false,
}: {
  tableId: string;
  seat: number | undefined;
  stack: number;
  seatReserved?: boolean;
  isRebuy?: boolean;
  showTimer?: boolean;
  sitOutOnCancel?: boolean;
}) => {
  return {
    type: LobbyInternalEvents.OPEN_BUY_IN,
    data: {
      tableId,
      seat,
      stack,
      seatReserved,
      isRebuy,
      showTimer,
      sitOutOnCancel,
    },
  };
};
export const LobbyTableUpdateBuyInAction = (
  tableId: string,
  seat: number,
  stack: number | undefined,
  txId?: `0x${string}`,
  seatReserved = false,
) => {
  return {
    type: LobbyInternalEvents.UPDATE_BUY_IN,
    data: {
      tableId,
      seat,
      stack,
      txId,
      seatReserved,
    },
  };
};

export const LobbyTableCloseBuyInAction = (tableId: string) => {
  return {
    type: LobbyInternalEvents.CLOSE_BUY_IN,
    data: {
      tableId,
    },
  };
};

export const LobbyCancelSeatReady = (tableId: string) => ({
  type: LobbyInternalEvents.CANCEL_SEAT_READY,
  tableId,
});

export const LobbyRemoveDuplicateLogin = () => {
  return {
    type: LobbyIncomingEvents.DUPLICATE_LOGIN,
    data: {
      visitorId: undefined,
    },
  };
};
