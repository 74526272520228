import { AxiosInstance } from 'axios';
import createAxiosInstance from '@/services/common/lib/createAxiosInstance';

const axiosInstancesCache: Record<string, AxiosInstance> = {};

export const getAxiosInstance = (baseURL: string): AxiosInstance => {
  if (!axiosInstancesCache[baseURL]) {
    axiosInstancesCache[baseURL] = createAxiosInstance(baseURL);
  }
  return axiosInstancesCache[baseURL];
};
