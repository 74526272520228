import { PlayerActions, PlayerIncomingEvents, TableIncomingEvents, TableInternalEvents } from '../table/tableActions';
import { PlayerEventMsg } from '../table/tableMessages';
import { AvailableActions } from '../table/tableState';
import { PlayerState } from './playerState';

type PlayerStates = Record<string, PlayerState>;

const initialState: PlayerStates = {};
const defaultState = {
  playerTurn: false,
  timeout: 0,
  cardIndexesForDiscard: [],
  refetchHandHistory: false,
  preAction: '' as PlayerActions | '' | undefined,
  preActionAmount: undefined,
  betOrRaiseAmount: undefined,
};

function playerReducer(state = initialState, action: PlayerEventMsg): PlayerStates {
  switch (action.type) {
    case PlayerIncomingEvents.MY_TURN: {
      const tableId = action.tableId;
      let player = state[tableId];

      if (!player) {
        player = {
          ...defaultState,
          tableId,
        };
      }

      const availableActions = Object.fromEntries(
        Object.values(action.data.availableActions).map((a) => [a, action.data.availableActions.includes(a)]),
      ) as AvailableActions;

      return {
        ...state,
        [tableId]: {
          ...player,
          playerTurn: true,
          availableActions,
        },
      };
    }

    case TableInternalEvents.ADD_DISCARD_CARD: {
      const tableId = action.data.tableId;
      const player = state[tableId];
      if (!player || !tableId) {
        return state;
      }

      return {
        ...state,
        [tableId]: {
          ...player,
          cardIndexesForDiscard: [...(player.cardIndexesForDiscard || []), action.data.cardIndex],
        },
      };
    }

    case TableInternalEvents.REMOVE_DISCARD_CARD: {
      const tableId = action.data.tableId;
      const player = state[tableId];

      if (!player || !tableId) {
        return state;
      }

      const discardIndex = player.cardIndexesForDiscard.findIndex((c) => c === action.data.cardIndex);

      if (discardIndex === -1) {
        throw Error('Card not found in discard list');
      }

      const newCardIndexesForDiscard = [...player.cardIndexesForDiscard];
      newCardIndexesForDiscard.splice(discardIndex, 1);

      return {
        ...state,
        [tableId]: {
          ...player,
          cardIndexesForDiscard: newCardIndexesForDiscard,
        },
      };
    }

    case PlayerActions.DISCARD: {
      const tableId = action.tableId;
      const player = state[tableId];

      if (!player) {
        return state;
      }

      if (!action.params.cards) {
        return state;
      }

      return {
        ...state,
        [tableId]: {
          ...player,
          cardIndexesForDiscard: [],
        },
      };
    }

    case TableInternalEvents.REFETCH_HISTORY: {
      const tableId = action.data.tableId;
      const player = state[tableId];

      if (!player) {
        return state;
      }
      return {
        ...state,
        [tableId]: {
          ...player,
          refetchHandHistory: false,
        },
      };
    }

    case TableIncomingEvents.HAND_FINISHED: {
      const tableId = action.data.tableId;
      const player = state[tableId];

      if (!player) {
        return state;
      }

      return {
        ...state,
        [tableId]: {
          ...player,
          refetchHandHistory: true,
          playerTurn: false,
          cardIndexesForDiscard: [],
          preAction: undefined,
          preActionAmount: undefined,
        },
      };
    }

    case TableInternalEvents.SET_PREACTION: {
      const tableId = action.tableId;
      let player = state[tableId];

      if (!player) {
        player = {
          ...defaultState,
          tableId,
        };
      }

      return {
        ...state,
        [tableId]: {
          ...player,
          preAction: action.data.action,
          preActionAmount: action.data.amount,
        },
      };
    }
    case TableInternalEvents.SET_BET_OR_RAISE_AMOUNT: {
      const tableId = action.tableId;
      let player = state[tableId];

      if (!player) {
        player = {
          ...defaultState,
          tableId,
        };
      }

      return {
        ...state,
        [tableId]: {
          ...player,
          betOrRaiseAmount: action.data.amount,
        },
      };
    }
    default:
      return state;
  }
}

export default playerReducer;
