import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from '@/assets/icons/close-icon.svg';

interface CloseModalButtonProps {
  onClose: (() => void) | undefined;
}
const CloseModalButton: FC<CloseModalButtonProps> = ({ onClose }) => {
  return (
    <div
      onClick={onClose}
      className="z-40 flex h-8 w-8 items-center justify-center rounded-full border border-ph-white-30 bg-transparent align-middle text-ph-white-50 hover:cursor-pointer hover:text-white"
    >
      <CloseIcon />
    </div>
  );
};

export default CloseModalButton;
