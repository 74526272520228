import { delay, call, fork, put, ForkEffect } from 'redux-saga/effects';

import { tableConnectAction } from '../tableActions';
import { chatConnectAction } from '../../chat/chatActions';
import { getUserTablesRest, rebuyTableRest } from '../tableService';
import { GetUserTablesResponse } from '../tableServicesTypes';
import { replacePathParams } from '@/common/utils/utils';
import ROUTES from '../../../routers/Routes';
import { AxiosResponse } from 'axios';
import { store } from '../../app/store';
import { LobbyTableCloseBuyInAction } from '@/services/lobby/lobbyActions';
import { toast } from '@/components/toast/useToast';

function* handleTable(tableId: string, tableUrl: string) {
  yield put(tableConnectAction(tableId, tableUrl));
  yield put(chatConnectAction(tableId, tableUrl));
}

export function* openAllTables(): Generator<ForkEffect> {
  const userId = store.getState().auth.id;
  if (!userId) return;
  yield fork(function* () {
    const response: AxiosResponse<GetUserTablesResponse, unknown> = yield call(() => getUserTablesRest(userId));

    response.data?.forEach((table: { tableId: string }) => {
      const url = replacePathParams(ROUTES.TABLE, { tableId: table.tableId });
      const width = 1440;
      const height = 900;
      window.open(url, table.tableId, `height=${height},width=${width}`);
    });
  });
}

export function* connectAllTables(): Generator<ForkEffect> {
  const userId = store.getState().auth.id;
  if (!userId) return;
  yield fork(function* () {
    yield delay(1000);
    const response: AxiosResponse<GetUserTablesResponse, unknown> = yield call(() => getUserTablesRest(userId));

    const tables = response.data;
    if (!tables) return;
    for (const table of tables) {
      yield call(handleTable, table.tableId, 'fakeURL'); //TODO: check this
    }
  });
}

export function* handleTablePlayerAutoTopUpSaga({ data }: any) {
  yield call(rebuyTableRest, {
    tableId: data.tableId,
    buyIn: data.amount,
    txId: data.txId,
    tableUrl: data.tableUrl,
  });
  yield put(LobbyTableCloseBuyInAction(data.tableId));
}

export function* handleRebuyProcessedSaga({ data }: any) {
  yield toast({
    description: `Rebuy confirmed. $${data.amount?.toFixed(2)} will be added to your chips stack next hand.`,
    open: true,
    variant: 'success',
  });
}
