import ROUTES from '@/routers/Routes';
import { ReducerState } from '@/services/common/lib/reducerState';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import SplashScreen from './SplashScreen/SplashScreen';
import LogoLoadingSpinner from '@/components/LogoLoadingSpinner/LogoLoadingSpinner';

const RegisteredGuard = () => {
  const isLoading = useSelector((state: ReducerState) => !!state.auth.loading);
  const registered = useSelector((state: ReducerState) => !!state.auth.alias);
  if (isLoading && !registered) {
    return (
      <SplashScreen logoHeader={false}>
        <LogoLoadingSpinner />
      </SplashScreen>
    );
  }

  return registered ? <Outlet /> : <Navigate to={ROUTES.REGISTER} />;
};

export default RegisteredGuard;
