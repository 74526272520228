import { polygon } from 'viem/chains';
import { Config } from './config.interface';

export const localConfig: Config = {
  environment: 'local',
  smartWalletEnabled: true,
  contracts: {
    usdt: '0x2A1e0E339355899f5F7f7c1F4590a79D6B49D4F2',
    phnm: '0x35F4a9FdcBddBaF7C3c3FF45f78Dc5BF70EeFDA1',
    tables: '0xe789D2724f29D8De7FA00a4e133b9072d6A492D9',
    redemption: '0x746c1e55d1FBeb0f6081C8b6805395E9f3811dDE',
    redemptionVault: '0x99810bF501E1122b209353AE0234a3cA94a73522',
  },
  chain: polygon,
  blockExplorerUrl: 'https://polygonscan.com',
  usdtDecimals: 18,
  phnmDecimals: 18,
};
