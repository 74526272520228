import { Wallet } from './walletsServicesTypes';

export enum WalletsRequest {
  WALLETS_REFRESH = 'WALLETS_REFRESH',
}

export enum WalletsInternalEvents {
  WALLETS_REFRESHED = 'WALLETS_REFRESHED',
}

export const walletsRefreshAction = () => ({
  type: WalletsRequest.WALLETS_REFRESH,
});

export const walletsRefreshed = (wallets: Wallet[]) => ({
  type: WalletsInternalEvents.WALLETS_REFRESHED,
  data: wallets,
});
