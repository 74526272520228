import { take, call, put, fork, cancelled, cancel } from 'redux-saga/effects';
import { EventChannel, Task, eventChannel } from 'redux-saga';
import { io, Socket } from 'socket.io-client';
import { TableIncomingEventMsg, TableConnectionMsg, MyPlayerTurnMsg } from '../tableMessages';
import { PlayerActions, PlayerIncomingEvents, TableIncomingEvents } from '../tableActions';
import { store } from '../../app/store';
import { ChatIncomingEvents, ChatActions } from '../../chat/chatActions';
import { getAccessToken } from '@privy-io/react-auth';

const sockets = new Map<string, Socket>();

function createTableSocketChannel(socket: Socket, tableId: string) {
  return eventChannel((emit) => {
    const playerHandlers = {
      [PlayerIncomingEvents.CONFIRM]: (_data: unknown, ack: (response: boolean) => void) => ack(true),
      [PlayerIncomingEvents.MY_TURN]: ({ data }: MyPlayerTurnMsg) => {
        if (!data || !data.availableActions) {
          console.error('Invalid data received in playerTurnHandler:', data);
          return;
        }
        // Use emit instead of store.dispatch
        emit({
          type: PlayerIncomingEvents.MY_TURN,
          tableId,
          data,
        });
      },
    };

    socket.onAny((eventType, data) => {
      if (
        Object.values(TableIncomingEvents).includes(eventType) ||
        Object.values(ChatIncomingEvents).includes(eventType)
      ) {
        emit({ type: eventType, data, tableId });
      }
    });

    // Set up listeners for PlayerIncomingEvents
    Object.keys(playerHandlers).forEach((key) => {
      socket.on(key, playerHandlers[key as keyof typeof playerHandlers]);
    });

    // Return a function to remove all listeners when the channel is closed
    return () => {
      socket.offAny();
      // Remove playerHandlers listeners
      Object.keys(playerHandlers).forEach((key) => {
        socket.off(key, playerHandlers[key as keyof typeof playerHandlers]);
      });
    };
  });
}

function dispatchRequest(action: any) {
  const socket = sockets.get(action.tableId);
  if (socket && socket.connected) {
    socket.emit(action.type, action.data);
  } else {
    console.warn(`Socket not connected for tableId: ${action.tableId}`);
  }
}

function dispatchAction(message: any) {
  const socket = sockets.get(message.tableId);
  if (socket && socket.connected) {
    console.log(`Dispatching action for table ${message.tableId}`);
    socket.emit('PLAYER_ACTION', message);
  } else {
    console.warn(`Socket not connected for tableId: ${message.tableId}`);
  }
}

function* handlePlayerAction(action: any) {
  yield call(dispatchAction, { ...action, tableId: action.tableId });
}

function* forwardEventsToServerTask(tableId: string): Generator {
  console.log(`Setting up forwarder for table ${tableId}`);

  // Handle ChatActions
  yield fork(function* () {
    while (true) {
      const action: any = yield take(
        (action: any) => Object.values(ChatActions).includes(action.type) && action.tableId === tableId,
      );
      console.log(`Forwarding chat action for table ${tableId}:`, action);
      yield call(dispatchRequest, action);
    }
  });

  // Handle PlayerActions
  yield fork(function* () {
    while (true) {
      const action: any = yield take(
        (action: any) => Object.values(PlayerActions).includes(action.type) && action.tableId === tableId,
      );
      console.log(`Forwarding player action for table ${tableId}:`, action);
      yield call(handlePlayerAction, action);
    }
  });
}

function handleSocketConnection(tableId: string, tableUrl: string): Socket | undefined {
  if (sockets.has(tableId)) {
    console.log(`Reusing existing socket for table ${tableId}`);
    return sockets.get(tableId);
  }

  if (!tableUrl) {
    throw new Error('No table URL available');
  }

  console.log(`Creating new socket connection for table ${tableId} and baseURL: ${tableUrl}`);

  const socket = io(`${tableUrl}/table/${tableId}/public`, {
    transports: ['websocket'],
    auth: async (cb) => {
      const token = await getAccessToken();
      cb({ token });
    },
    withCredentials: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  });

  sockets.set(tableId, socket);

  let tokenRefreshInterval: NodeJS.Timeout;

  const startTokenRefresh = () => {
    // Refresh token every 45 minutes
    tokenRefreshInterval = setInterval(
      async () => {
        const newToken = await getAccessToken();
        socket.auth = { token: newToken };
      },
      45 * 60 * 1000,
    );
  };

  const stopTokenRefresh = () => {
    if (tokenRefreshInterval) {
      clearInterval(tokenRefreshInterval);
    }
  };

  // Start token refresh after socket connects
  socket.on('connect', () => {
    console.log(`Connected to public ${tableId} socket`);
    sockets.set(tableId, socket);
    store.dispatch({
      type: TableIncomingEvents.TABLE_CONNECTED,
      tableId,
    });
    startTokenRefresh();
  });

  // Stop token refresh when socket disconnects
  socket.on('disconnect', (reason) => {
    console.log(`Socket disconnected for table ${tableId}. Reason:`, reason);
    sockets.delete(tableId);
    store.dispatch({ type: TableIncomingEvents.TABLES_DISCONNECTED, tableId });
    stopTokenRefresh();
  });

  // Update auth token before each reconnection attempt
  socket.io.on('reconnect_attempt', async () => {
    const newToken = await getAccessToken();
    socket.auth = { token: newToken };
  });

  socket.on('auth_error', (data) => {
    console.error('Authentication error:', data.message);
  });

  socket.on('error', (error) => {
    console.error(`Socket error for table ${tableId}:`, error);
    store.dispatch({ type: TableIncomingEvents.ERROR, error });
  });

  return socket;
}

function* setupEventChannel(socket: Socket, tableId: string): Generator<any, void, any> {
  const channel: EventChannel<TableIncomingEventMsg> = yield call(createTableSocketChannel, socket, tableId);

  const eventForwarderTask: Task = yield fork(forwardEventsToServerTask, tableId);

  try {
    while (true) {
      const message: TableIncomingEventMsg = yield take(channel);
      yield put(message);
    }
  } catch (error) {
    console.error(`Error in setupEventChannel for table ${tableId}:`, error);
  } finally {
    if (yield cancelled()) {
      channel.close();
      eventForwarderTask.cancel();
    }
  }
}

function* tableRootSaga(action: TableConnectionMsg): Generator<any, void, any> {
  const { tableId, tableUrl } = action;

  if (sockets.has(tableId)) {
    console.log(`Table ${tableId} is already connected`);
    return;
  }

  try {
    const socket: Socket = yield call(handleSocketConnection, tableId, tableUrl);

    // Updated take effect with filtering by tableId (from previous recommendation)
    yield take(
      (incomingAction: any) =>
        incomingAction.type === TableIncomingEvents.TABLE_CONNECTED && incomingAction.tableId === tableId,
    );

    console.log(`Socket connected for table ${tableId}`);
    const sagaTask: Task = yield fork(setupEventChannel, socket, tableId);

    try {
      yield sagaTask;
    } finally {
      if (yield cancelled()) {
        console.log(`Cancelling saga for table ${tableId}`);
        yield cancel(sagaTask);
        socket.disconnect();
        sockets.delete(tableId);
      }
    }
  } catch (error) {
    console.error(`Error in tableRootSaga for table ${tableId}:`, error);

    // Properly disconnect and clean up the socket
    if (sockets.has(tableId)) {
      const socket = sockets.get(tableId);
      if (socket) {
        socket.disconnect();
        sockets.delete(tableId);
      }
    }
  }
}

export default tableRootSaga;
