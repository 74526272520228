// All this info is an override

import { put, call } from 'redux-saga/effects';
import { LobbyInternalEvents } from '../lobbyActions';
import { AxiosResponse } from 'axios';
import { LobbyState } from '../lobbyState';

export function* lobbyRefresh() {
  const response: AxiosResponse = yield call(() => {});

  // gets axios response
  const { tables } = response.data;

  const lobbySnap: LobbyState = {
    tables: tables,
    userTables: [],
    isConnected: true,
    playersOnline: 1,
    activeCashTables: tables.length,
    activeCashTournaments: 0,
  };
  // dispatch to reducer
  yield put({
    type: LobbyInternalEvents.LOBBY_REFRESHED,
    data: lobbySnap,
  });
}
