import { call, put } from 'redux-saga/effects';
import { ChatRequestHistoryMsg } from '../chatMessages';
import { getChatsHistoryRest } from '../chatService';
import { getChats } from '../chatActions';
import { AxiosResponse } from 'axios';

export default function* getChatsHistorySaga(action: ChatRequestHistoryMsg) {
  try {
    const { tableUrl } = action;
    const response: AxiosResponse = yield call(getChatsHistoryRest, {
      tableId: action.tableId,
      hoursAgo: 1,
      tableUrl,
    });

    yield put(getChats(action.tableId, response.data));
  } catch (error) {
    console.error('Error in fetchChatsSaga:', error);
    // Handle the error based on your application's requirements
  }
}
