import { polygon } from 'viem/chains';
import { Config } from './config.interface';

export const stagingConfig: Config = {
  environment: 'staging',
  smartWalletEnabled: true,
  contracts: {
    usdt: '0x2A1e0E339355899f5F7f7c1F4590a79D6B49D4F2',
    phnm: '0xE24D8d07AC6c54Ff358b00c1Bc5dB4A6f73a42cb',
    tables: '0xe789D2724f29D8De7FA00a4e133b9072d6A492D9',
    redemption: '0xe28C4129828e8a0900e3AD01BB9419e13815aA15',
    redemptionVault: '0x0cACa68A160b2c43C248C1E2b045c3e09C2E02c2',
  },
  chain: polygon,
  blockExplorerUrl: 'https://polygonscan.com',
  usdtDecimals: 18,
  phnmDecimals: 18,
};
