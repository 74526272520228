import { combineReducers } from '@reduxjs/toolkit';
import tableReducer from '../table/tableReducer';
import lobbyReducer from '../lobby/lobbyReducer';
import walletsReducer from '../wallets/walletsReducer';
import playerReducer from '../players/playerReducer';
import chatReducer from '../chat/chatReducer';
import authReducer from '../auth/authReducer';

export interface RootState {
  tables: ReturnType<typeof tableReducer>;
  lobby: ReturnType<typeof lobbyReducer>;
  players: ReturnType<typeof playerReducer>;
  auth: ReturnType<typeof authReducer>;
  wallets: ReturnType<typeof walletsReducer>;
}

const rootReducer = combineReducers({
  lobby: lobbyReducer,
  tables: tableReducer,
  chats: chatReducer,
  players: playerReducer,
  auth: authReducer,
  wallets: walletsReducer,
});

export default rootReducer;
