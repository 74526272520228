import axios from 'axios';
import { authInterceptor, errorInterceptor } from './authInterceptor';

const axiosBaseApi = axios.create({
  baseURL: process.env.REACT_APP_PHENOM_API || '',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosBaseApi.interceptors.request.use(authInterceptor, errorInterceptor);

export default axiosBaseApi;
