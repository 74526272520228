import { WalletsState } from './walletsState';
import { WalletsInternalEvents } from './walletsActions';
import { WalletsIncomingEventMsg } from './walletsMessages';

const initialState: WalletsState = {
  wallets: [],
};

function walletsReducer(state = initialState, action: WalletsIncomingEventMsg): WalletsState {
  switch (action.type) {
    case WalletsInternalEvents.WALLETS_REFRESHED:
      return { ...state, wallets: action.data };
    default:
      return state;
  }
}

export default walletsReducer;
