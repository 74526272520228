import makeContextStore, { Action } from '@/hooks/makeContextStore';
import { BiconomySmartAccountV2 } from '@biconomy/account';

export enum AccountAbstractionActionTypes {
  SET_LOADING = 'SET_LOADING',
  SET_LOADING_JOIN_TABLE_SESSION = 'SET_LOADING_JOIN_TABLE_SESSION',
  SET_LOADING_REBUY_SESSION = 'SET_LOADING_REBUY_SESSION',
  SET_LOADING_REDEEM_SESSION = 'SET_LOADING_REDEEM_SESSION',
  SET_LOADING_TRANSACTION = 'SET_LOADING_TRANSACTION',
  SET_INITIALIZED = 'SET_INITIALIZED',
  SET_SMART_ACCOUNT = 'SET_SMART_ACCOUNT',
  SET_SMART_WALLET_ADDRESS = 'SET_SMART_WALLET_ADDRESS',
  SET_SESSION_KEY = 'SET_SESSION_KEY',
  SET_SESSION_STORAGE_CLIENT = 'SET_SESSION_STORAGE_CLIENT',
  SET_SESSION = 'SET_SESSION',
  SET_JOIN_TABLE_BATCH_SESSION = 'SET_JOIN_TABLE_BATCH_SESSION',
  SET_REDEEM_BATCH_SESSION = 'SET_REDEEM_BATCH_SESSION',
  SET_REBUY_BATCH_SESSION = 'SET_REBUY_BATCH_SESSION',
  SET_EMULATED_SMART_ACCOUNT = 'SET_EMULATED_SMART_ACCOUNT',
  DELETE_SESSIONS = 'DELETE_SESSIONS',
  DELETE_JOIN_TABLE_SESSION = 'DELETE_JOIN_TABLE_SESSION',
  DELETE_REBUY_SESSION = 'DELETE_REBUY_SESSION',
  DELETE_REDEEM_SESSION = 'DELETE_REDEEM_SESSION',
}

export interface AccountAbstractionState {
  loading: boolean;
  loadingTransaction: boolean;
  initialized: boolean;
  smartAccount: BiconomySmartAccountV2 | null;
  smartWalletAddress?: `0x${string}` | undefined;
  sessionKeyAddress?: `0x${string}` | undefined;
  sessionStorageClient?: any | undefined;
  session?: any; // this should be saved in the local storage
  redeemBatchSession?: any; // this should be saved in the local storage
  emulatedUsersSmartAccount?: BiconomySmartAccountV2;
}

export type AccountAbstractionAction = Action<AccountAbstractionActionTypes>;

const initialState: AccountAbstractionState = {
  loading: false,
  loadingTransaction: false,
  initialized: false,
  smartAccount: null,
};

const accountAbstractionReducer = (state: AccountAbstractionState, action: AccountAbstractionAction) => {
  switch (action.type) {
    case AccountAbstractionActionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case AccountAbstractionActionTypes.SET_LOADING_JOIN_TABLE_SESSION:
      return { ...state, loadingJoinTableSession: action.payload };
    case AccountAbstractionActionTypes.SET_LOADING_REBUY_SESSION:
      return { ...state, loadingRebuySession: action.payload };
    case AccountAbstractionActionTypes.SET_LOADING_REDEEM_SESSION:
      return { ...state, loadingRedeemSession: action.payload };
    case AccountAbstractionActionTypes.SET_LOADING_TRANSACTION:
      return { ...state, loadingTransaction: action.payload };
    case AccountAbstractionActionTypes.SET_INITIALIZED:
      return { ...state, initialized: action.payload };
    case AccountAbstractionActionTypes.SET_SMART_ACCOUNT:
      return { ...state, smartAccount: action.payload };
    case AccountAbstractionActionTypes.SET_SMART_WALLET_ADDRESS:
      return { ...state, smartWalletAddress: action.payload };
    case AccountAbstractionActionTypes.SET_SESSION_KEY:
      return { ...state, sessionKeyAddress: action.payload };
    case AccountAbstractionActionTypes.SET_SESSION_STORAGE_CLIENT:
      return { ...state, sessionStorageClient: action.payload };
    case AccountAbstractionActionTypes.SET_SESSION:
      return { ...state, session: action.payload };
    case AccountAbstractionActionTypes.SET_JOIN_TABLE_BATCH_SESSION:
      return { ...state, joinTableBatchSession: action.payload };
    case AccountAbstractionActionTypes.SET_REDEEM_BATCH_SESSION:
      return { ...state, redeemBatchSession: action.payload };
    case AccountAbstractionActionTypes.SET_REBUY_BATCH_SESSION:
      return { ...state, rebuyBatchSession: action.payload };
    case AccountAbstractionActionTypes.SET_EMULATED_SMART_ACCOUNT:
      return { ...state, emulatedUsersSmartAccount: action.payload };
    case AccountAbstractionActionTypes.DELETE_SESSIONS:
      return {
        ...state,
        session: undefined,
        batchSession: undefined,
        rebuyBatchSession: undefined,
        redeemBatchSession: undefined,
      };
    case AccountAbstractionActionTypes.DELETE_JOIN_TABLE_SESSION:
      return {
        ...state,
        joinTableBatchSession: undefined,
      };
    case AccountAbstractionActionTypes.DELETE_REBUY_SESSION:
      return {
        ...state,
        rebuyBatchSession: undefined,
      };
    case AccountAbstractionActionTypes.DELETE_REDEEM_SESSION:
      return {
        ...state,
        redeemBatchSession: undefined,
      };
    default:
      return state;
  }
};

const [
  AccountAbstractionProvider,
  useAccountAbstractionStore,
  useAccountAbstractionDispatch,
  AccountAbstractionContext,
] = makeContextStore<AccountAbstractionActionTypes, AccountAbstractionState>(
  'AccountAbstraction',
  accountAbstractionReducer,
  initialState,
);

export {
  AccountAbstractionProvider,
  useAccountAbstractionStore,
  useAccountAbstractionDispatch,
  AccountAbstractionContext,
};
