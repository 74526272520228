import { Config } from './config.interface';
import { localConfig } from './local.config';
import { developConfig } from './develop.config';
import { stagingConfig } from './staging.config';
import { productionConfig } from './production.config';

type NodeEnv = 'development' | 'production' | 'test' | 'staging';

let config: Config;

switch (process.env.REACT_APP_NODE_ENV as NodeEnv) {
  case 'production':
    config = productionConfig;
    break;
  case 'staging':
    config = stagingConfig;
    break;
  case 'development':
    config = developConfig;
    break;
  default:
    config = localConfig;
}

export default config;
