import axios from 'axios';
import { authInterceptor, errorInterceptor } from './authInterceptor';

const isLocal = process.env.REACT_APP_NODE_ENV === 'local';

const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL: isLocal ? `http://${baseURL}` : `https://${baseURL}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.request.use(authInterceptor, errorInterceptor);

  return instance;
};

export default createAxiosInstance;
